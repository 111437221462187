import Helmet from "react-helmet";
import React from "react";

import ContentCard from "../components/ContentCard.js";
import TemplateWrapper from "../layouts";

const CodeOfConductPage = () => (
  <TemplateWrapper>
    <div className="container">
      <Helmet>
        <title>Code Of Conduct | PyCon SG 2019</title>
      </Helmet>
      <ContentCard>
        <h1>Code of Conduct</h1>
        <p>
          PyCon SG is a community conference intended for networking and
          collaboration in the developer community.
        </p>
        <p>
          We value the participation of each member of the Python community and
          want all attendees to have an enjoyable and fulfilling experience.
          Accordingly, all attendees are expected to show respect and courtesy
          to other attendees throughout the conference and at all conference
          events, whether officially sponsored by PyCon or not.
        </p>
        <p>
          To make clear what is expected, all delegates/attendees, speakers,
          exhibitors, organizers and volunteers at any PyCon event are required
          to conform to the following Code of Conduct. Organizers will enforce
          this code throughout the event.
        </p>

        <h2>The Short Version</h2>
        <p>
          PyCon SG is dedicated to providing a harassment-free conference
          experience for everyone, regardless of gender, sexual orientation,
          disability, physical appearance, body size, race, or religion. We do
          not tolerate harassment of conference participants in any form.
        </p>
        <p>
          All communication should be appropriate for a professional audience
          including people of many different backgrounds. Sexual language and
          imagery is not appropriate for any conference venue, including talks.
        </p>
        <p>
          Be kind to others. Do not insult or put down other attendees. Behave
          professionally. Remember that harassment and sexist, racist, or
          exclusionary jokes are not appropriate for PyCon SG.
        </p>
        <p>
          Attendees violating these rules may be asked to leave the conference
          without a refund at the sole discretion of the conference organizers.
        </p>
        <p>
          Thank you for helping make this a welcoming, friendly event for all.
        </p>

        <h2>The Longer Version</h2>
        <p>
          Harassment includes offensive communication related to gender, sexual
          orientation, disability, physical appearance, body size, race,
          religion, sexual images in public spaces, deliberate intimidation,
          stalking, following, harassing photography or recording, sustained
          disruption of talks or other events, inappropriate physical contact,
          and unwelcome sexual attention.
        </p>
        <p>
          Participants asked to stop any harassing behavior are expected to
          comply immediately.
        </p>
        <p>
          Exhibitors in the expo hall, sponsor or vendor booths, or similar
          activities are also subject to the anti-harassment policy. In
          particular, exhibitors should not use sexualized images, activities,
          or other material. Booth staff (including volunteers) should not use
          sexualized clothing/uniforms/costumes, or otherwise create a
          sexualized environment.
        </p>
        <p>
          Be careful in the words that you choose. Remember that sexist, racist,
          and other exclusionary jokes can be offensive to those around you.
          Excessive swearing and offensive jokes are not appropriate for PyCon
          SG.
        </p>
        <p>
          If a participant engages in behavior that violates this code of
          conduct, the conference organizers may take any action they deem
          appropriate, including warning the offender or expulsion from the
          conference with no refund.
        </p>

        <h2>License</h2>
        <p>
          This Code of Conduct was forked from the example policy from the{" "}
          <a href="http://geekfeminism.wikia.com/wiki/Conference_anti-harassment/Policy">
            Geek Feminism wiki, created by the Ada Initiative and other
            volunteers
          </a>
          , which is under a Creative Commons Zero license.{" "}
        </p>
        <p>
          <a href="http://creativecommons.org/licenses/by/3.0/">
            <img
              src="//i.creativecommons.org/l/by/3.0/88x31.png"
              alt="Creative Commons License"
            />
          </a>
        </p>
        <p>
          Conference Code of Conduct by{" "}
          <a href="https://pycon.sg/code-of-conduct">
            https://pycon.sg/code-of-conduct/
          </a>{" "}
          is licensed under a{" "}
          <a href="http://creativecommons.org/licenses/by/3.0/">
            Creative Commons Attribution 3.0 Unported License
          </a>
          .
        </p>
      </ContentCard>
    </div>
  </TemplateWrapper>
);

export default CodeOfConductPage;
